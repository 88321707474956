import React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import Benefits from "src/components/pages/product/sections/benefits/Benefits";
import Header from "src/components/layout/Header/Header";
import NewHero from "src/components/pages/index/sections/NewHero/NewHero";
import Paragraph from "src/components/pages/index/sections/paragraph/Paragraph";
import OtherModules from "src/components/pages/product/sections/otherModules/OtherModules";
import CommonDescription from "src/components/layout/CommonDescription/CommonDescription";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";

export default function brandingPage() {
    return (
        <Layout>
            <Seo />
            <Header>
                <NewHero pageName="branding" />
                <Paragraph title="Firma, z którą chce się pracować!" desc="" />
            </Header>
            <Benefits pageName="branding" />
            <CommonDescription listColor="#75529D" />
            <OtherModules pageName="branding" />
            <ContactFooter showForm={true} showInfo={true} />
            <Patrons />
        </Layout>
    );
}